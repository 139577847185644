import { useState, useEffect, React } from "react";
import {api} from '../api.js'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from "../Components/Header.jsx";
import '../Styles/contracts.css'

function Contratos() {
    const [contracts, setContracts] = useState([])
    const [deleteContractId, setDeleteContractId] = useState()
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [filteredContracts, setFilteredContracts] = useState([]);
    const [isPendentesActive, setIsPendentesActive] = useState(false);
    const [pageInfo, setPageInfo] = useState({ page: 0, pages: 0 });
    const [searchState, setSearchState] = useState('')
    const [user, setUser] = useState()
    const [users, setUsers] = useState()

    const navigate = useNavigate()

    const useQuery = () => new URLSearchParams(useLocation().search)
    const query = useQuery()
    let page = query.get('page')
    let search = query.get('search')
    let status = query.get('status')
    console.log(status)
    console.log(search)
    let select = 0

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        setUser(user);
    
        // Ativar feedback de carregamento
        handleLoader(true); // Inicia o carregamento
    
        api.get('/local/getAllUsers')
            .then(res => {
                setUsers(res.data[0].users);
            });
    
        if (typeof (page) !== 'string') {
            page = 1;
        }
        if (typeof (search) !== 'string') {
            search = null;
        }
        if (typeof (status) !== 'string') {
            status = null;
        }
    
        // Caso search seja null, não realiza filtragem e mantém o estado
        if (search === null) {
            // No case search === null, only load contracts based on status if available
            if (status) {
                api.get('/contract/contractsG', { params: { userId: user.userId, status: status, page: page } })
                    .then(res => {
                        const contracts = res.data.data;
                        setFilteredContracts(contracts);
                        handlePageConfig(res.data)
                        setFeedbackMessage(""); // Limpa mensagem de erro
                        handleLoader(false); // Finaliza o carregamento
                    })
                    .catch(error => {
                        console.error("Erro ao buscar contratos filtrados:", error);
                        setFeedbackMessage("Erro ao carregar contratos filtrados.");
                        handleLoader(false); // Finaliza o carregamento
                    });
            } else {
                handleLoader(false); // Se não houver status nem search, finaliza o carregamento
            }
        }
    
        // Quando search é vazio e status existe, carregamos os contratos com o status definido
        if (search === '' && status) {
            api.get('/contract/contractsG', { params: { userId: user.userId, status: status, page: page } })
                .then(res => {
                    const contracts = res.data.data;
                    setFilteredContracts(contracts);
                    handlePageConfig(res.data)
                    setFeedbackMessage(""); // Limpa mensagem de erro
                    handleLoader(false); // Finaliza o carregamento
                })
                .catch(error => {
                    console.error("Erro ao buscar contratos filtrados:", error);
                    setFeedbackMessage("Erro ao carregar contratos filtrados.");
                    handleLoader(false); // Finaliza o carregamento
                });
    
            // Ajusta o estilo do status
            for (let i = 0; i < 7; i++) {
                document.getElementById('status' + i).style.fontWeight = 'normal';
            }
            switch (status) {
                case '2432': document.getElementById('status1').style.fontWeight = 'bold'; break;
                case '2433': document.getElementById('status2').style.fontWeight = 'bold'; break;
                case '2434': document.getElementById('status3').style.fontWeight = 'bold'; break;
                case '2435': document.getElementById('status4').style.fontWeight = 'bold'; break;
                case '2436': document.getElementById('status5').style.fontWeight = 'bold'; break;
                case '2437': document.getElementById('status6').style.fontWeight = 'bold'; break;
                default: return navigate('/contratos');
            }
        }
        else if (status === null && search !== null) {
            // Quando o status é null, mas o search não é, carrega contratos com base no search
            api.get('/contract/contractsG2', { params: { page: page, search: search, userId: user.userId } }) // Get contract sem status
                .then(res => {
                    const contracts = res.data.data;
                    setFilteredContracts(contracts);
                    handlePageConfig(res.data)
                    setFeedbackMessage(`Nenhum contrato encontrado para o termo: "${search}".`);
                    handleLoader(false); // Finaliza o carregamento
                })
                .catch(error => {
                    console.error("Erro ao buscar contratos filtrados:", error);
                    setFeedbackMessage("Erro ao carregar contratos filtrados.");
                    handleLoader(false); // Finaliza o carregamento
                });
        } else {
            // Quando tanto search quanto status estão definidos, filtra contratos com base em ambos
            api.get('/contract/contractsG', { params: { page: page, search: search, status: status, userId: user.userId } })
                .then(res => {
                    const contracts = res.data.data;
                    setFilteredContracts(contracts);
                    handlePageConfig(res.data)
                    setFeedbackMessage(`Nenhum contrato encontrado para o termo: "${search}".`);
                    handleLoader(false); // Finaliza o carregamento
                })
                .catch(error => {
                    console.error("Erro ao buscar contratos filtrados:", error);
                    setFeedbackMessage("Erro ao carregar contratos filtrados.");
                    handleLoader(false); // Finaliza o carregamento
                });
    
            // Ajusta o estilo do status
            for (let i = 0; i < 7; i++) {
                document.getElementById('status' + i).style.fontWeight = 'normal';
            }
            switch (status) {
                case '2432': document.getElementById('status1').style.fontWeight = 'bold'; break;
                case '2433': document.getElementById('status2').style.fontWeight = 'bold'; break;
                case '2434': document.getElementById('status3').style.fontWeight = 'bold'; break;
                case '2435': document.getElementById('status4').style.fontWeight = 'bold'; break;
                case '2436': document.getElementById('status5').style.fontWeight = 'bold'; break;
                case '2437': document.getElementById('status6').style.fontWeight = 'bold'; break;
                default: return navigate('/contratos');
            }
        }
    
    }, [page, search, status]);

    async function handlePendentesClick() {
        setIsPendentesActive(true);
        // Ativa a lógica de pendências
        document.getElementById('wrapper').style.display = 'flex';
    
        try {
            const userId = user?.userId; // Certifique-se de que o usuário existe
            let allContracts = []; // Array para acumular todos os contratos
            let page = 1;
            let hasMore = true;
    
            // Carregar todas as páginas
            while (hasMore) {
                const res = await api.get('/contract/contractsG', {
                    params: { userId, status: '2434', page }
                });
    
                const contracts = res.data?.data || [];
                allContracts = [...allContracts, ...contracts]; // Adiciona os contratos da página atual
    
                handlePageConfig(res.data);
    
                // Se a página atual tiver menos contratos do que a quantidade por página, significa que é a última página
                hasMore = contracts.length > 0; // Verifica se há mais contratos
                page++; // Passa para a próxima página
            }
    
            // Aplica o filtro diretamente nos contratos carregados
            const contractsFiltered = allContracts.filter(contract => {
                if (contract.current_document && contract.current_document.proofs && contract.current_document.proofs.length > 0) {
                    return contract.current_document.proofs.some(proof => {
                        if (proof.subjects && proof.subjects.length > 0) {
                            return proof.subjects.every(subject => {
                                const allConfirmed = subject.confirmed_at !== null;
                                const hasCompanyName = subject.company_name !== null;
                                return allConfirmed || hasCompanyName;
                            });
                        }
                        return false;
                    });
                }
                return false;
            });
    
            setContracts(contractsFiltered); // Atualiza o estado com os contratos filtrados
            setFilteredContracts(contractsFiltered); // Se necessário, também mantém o estado dos contratos filtrados
    
            if (contractsFiltered.length === 0) {
                setFeedbackMessage("Nenhum contrato encontrado para as pendências.");
            } else {
                setFeedbackMessage("");
            }
    
            setFiltro(page, 'page');
        } catch (error) {
            console.error("Erro ao buscar pendências:", error);
            setFeedbackMessage("Erro ao buscar contratos.");
        } finally {
            handleLoader(false);
        }
    }
    
    
    

    function handleLoader(isLoading = true) {
        const loader = document.getElementById('wrapper');
        if (isLoading) {
            loader.style.display = 'flex'; // Exibe o loader
        } else {
            loader.style.display = 'none'; // Esconde o loader
        }
    }

    async function handlePageConfig(data){
        setPageInfo({
            page: data.page_number,
            pages: data.total_pages
        })
        let nextPage = document.getElementById('next-page-button')
        let previousPage = document.getElementById('previous-page-button')
        
        if(data.page_number === data.total_pages){
            nextPage.classList.add('disabled')
        }
        else {
            nextPage.classList.remove('disabled')
        }

        if(data.page_number === 1){
            previousPage.classList.add('disabled')
        }
        else{
            previousPage.classList.remove('disabled')
        }
    }

    function getColor(color){
        switch(color){
            case 1: return '#359be8'
            case 2: return '#feeb3b'
            case 3: return '#fd9827'
            case 4: return '#2e7d32'
            case 5: return '#db2828'
            default: return 0
        }
    }

    function formatDate(string){
        const data = string.includes('Z') ? new Date(string) : new Date(`${string}Z`)
        
        const dia = data.getDate().toString().padStart(2, '0')
        const mes = (data.getMonth() + 1).toString().padStart(2, '0')
        const ano = data.getFullYear()
        const horas = data.getHours().toString().padStart(2, '0')
        const minutos = data.getMinutes().toString().padStart(2, '0')
        const segundos = data.getSeconds().toString().padStart(2, '0')

        const formatada = `${dia}/${mes}/${ano} às ${horas}:${minutos}:${segundos}`
        return formatada
    }

    async function openDeleteModal(contractId, status) {
        let modal = ''
        status === 'Ag.Assinatura' || status === 'Vigente' ? modal = 'non-delete-modal' : modal = 'delete-modal'
        document.getElementById(modal).style.display = 'block'
        setDeleteContractId(contractId)
    }

    async function deleteContract() {
        document.getElementById('wrapper').style.display = 'flex'
        api.delete("/contract/contracts", { params: { id: deleteContractId, userId: user.userId } }).then(res => {
            api.delete('/local/deleteContract', { params: { contractId: deleteContractId } }).then(res => {
                navigate(0)
            })
        })
    }

    async function cancelDeleteContract(modal){
        setDeleteContractId()
        document.getElementById(modal).style.display = 'none'
    }

    async function setFiltro(value, type){
        switch(type){
            case 'page':
                if(value <= pageInfo.pages && value >= 1){
                    document.getElementById('wrapper').style.display = 'flex'
                    if(search !== null && status !== null){
                        navigate(`/contratos?search=${search}&status=${status}&page=${value}`)
                    }
                    else if(search !== null && status == null){
                        navigate(`/contratos?search=${search}&page=${value}`)
                    }
                    else if(search === null && status !== null){
                        navigate(`/contratos?status=${status}&page=${value}`)
                    }
                    else if(search === null && status === null){
                        navigate(`/contratos?page=${value}`)
                    }
                }
            break;
            case 'search':
                document.getElementById('wrapper').style.display = 'flex'
                if(value === ''){
                    if(status !== null){
                        navigate(`/contratos?status=${status}`)
                    }
                    if(status === null){
                        if(search === null){
                            navigate(0)
                        }
                        else{
                            navigate(`/contratos`)
                        }
                        
                    }
                }
                else{
                    if(status !== null){
                        navigate(`/contratos?search=${value}&status=${status}`)
                    }
                    if(status === null){
                        navigate(`/contratos?search=${value}`)
                    }
                }
            break;
            case 'status':
                document.getElementById('wrapper').style.display = 'flex'
                if(search !== null){
                    if(value === ''){
                        navigate(`/contratos?search=${search}`)
                    }
                    else{
                        navigate(`/contratos?search=${search}&status=${value}`)
                    }
                }
                if(search === null){
                    if(value === ''){
                        if(status === null){
                            navigate(0)
                        }
                        else{
                            navigate(`/contratos`)
                        }
                    }
                    else{
                        navigate(`/contratos?status=${value}`)
                    }
                    
                }
                filterSelect()
            break;
            default: return 0
        }
    }

    function filterSelect(){
        let selectDiv = document.getElementById('custom-select-status-div')
        if(select === 0){
            selectDiv.style.display = 'flex'
            select = 1
        }
        else if(select === 1){
            selectDiv.style.display = 'none'
            select = 0
        }
    }

    async function handleKeyPress(e){
        if(e.key === 'Enter') {
            setFiltro(searchState, 'search')
        }
    }

    function openFormModal(){
        document.getElementById('form-modal').style.display = 'block'
    }

    function copyFormLink(){
        navigator.clipboard.writeText('https://app.contraktor.com.br/form/9b3ea6b4-ace0-4db5-8422-2b9e50c8a12c')
        document.getElementById('copy-link').innerHTML = 'Link copiado!'
        setTimeout(() => {
            document.getElementById('copy-link').innerHTML = 'Copiar link'
        }, 3000)
    }

    function getInitials(shares, signer, contractId){
        let signerName = ''
        if(signer !== null){
            if(signer[0] === 'API' && signer[1] !== undefined){
                signerName = users.filter(user => user.userId == signer[1])[0].userName
                return (
                    <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{ }}>
                            <div style={{ width: '36px', height: '36px', backgroundColor: '#03a9f4', color: 'white', display: 'flex', alignItems: 'center'
                            , justifyContent: 'center', cursor: 'pointer', border: '1px solid white', borderRadius: '150px'  }}
                            onMouseEnter={() => document.getElementById('box' + contractId).style.display = 'block'}
                            onMouseLeave={() => document.getElementById('box' + contractId).style.display = 'none'}>
                                <p style={{ fontSize: '19px', fontFamily: 'Fakt Medium' }}>{getInitialsString(signerName)}</p>
                            </div>
                            <div id={'box' + contractId} style={{ transition: '0.2s', position: 'absolute', display: 'none', zIndex: '999', backgroundColor: '#e0e6ed', color: '#777777',
                            transform: 'translateY(-80px)', borderRadius: '5px' }}>
                                <p style={{ padding: '10px' }}>{signerName}</p>
                            </div>
                        </div>
                        { shares.map((share, i) => (
                            <div style={{}}>
                                <div style={{ borderRadius: '150px', width: '36px', height: '36px', backgroundColor: '#03a9f4', color: 'white', display: 'flex', alignItems: 'center'
                                , justifyContent: 'center', cursor: 'pointer', border: '1px solid white', transform: `translateX(-${10 * (i + 1)}px)` }}
                                onMouseEnter={() => document.getElementById('box' + share.id).style.display = 'block'}
                                onMouseLeave={() => document.getElementById('box' + share.id).style.display = 'none'}>
                                    {share.company !== null ? (
                                        <p style={{ fontSize: '19px', fontFamily: 'Fakt Medium' }}>{getInitialsString(share.company.name)}</p>
                                    ) : (
                                        <p style={{ fontSize: '19px', fontFamily: 'Fakt Medium' }}>{getInitialsString(share.party.name)}</p>
                                    )}
                                </div>
                                <div id={'box' + share.id} style={{ transition: '0.2s', position: 'absolute', display: 'none', zIndex: '999', backgroundColor: '#e0e6ed', color: '#777777',
                                transform: `translateY(-80px) translateX(-${10 * (i + 1)}px)`, borderRadius: '5px', width: 'max-content' }}>
                                    {share.company !== null ? (
                                        <p style={{ padding: '10px',  }}>{share.company.name.length <= 26 ? share.company.name : share.company.name.substr(0,26) + "..."}</p>
                                    ) : (
                                        <p style={{ padding: '10px',  }}>{share.party.name.length <= 26 ? share.party.name : share.party.name.substr(0,26) + "..."}</p>
                                    )}
                                    
                                </div>
                            </div>
                        ))}
                        
                    </div>
                )
            }
        }
        //shares.forEach((share) => console.log(share.party.name.substring(0,1)))
    }

    function getInitialsString(name){
        const names = name.split(' ')
        const nameInitials = names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase()
        return nameInitials
    }

    return (
        <div>
            <div id="wrapper">
                <div id="loader"></div>
            </div>
            <Header />
            <div className="page">
                <div className="contracts-page-container">
                    <div className="contracts-top">
                        <div className="contracts-top-left" style={{ width: '50%' }}>
                            <a href="/contratos" className="contracts-page-button" style={{ textDecoration: 'none' }}><p>Documentos</p></a>
                            <a href="/contratos?search=formulário&page=1" className="contracts-page-button" style={{ textDecoration: 'none' }}><p>Formulários</p></a>
                        </div>
                        <div className="contracts-top-right" style={{ width: '50%' }}>
                            { search === 'formulário' ? (
                                <button className="contracts-new-contract" onClick={() => openFormModal()}>Novo Formulário</button>
                            ) : (
                                <button className="contracts-new-contract" onClick={() => navigate('/contratos/novo')}>Novo Documento</button>
                            )}
                        </div>
                    </div>
                    <div className="contracts-middle" style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="contract-filters-left" style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                            <img alt="lupa" onClick={() => setFiltro(searchState, 'search')} src="icon-magnifying.svg" width='30px' height='auto' className="svg-settings" style={{ marginLeft: '20px', cursor: 'pointer' }}></img>
                            <input type="text" placeholder="Buscar..." className="search-model-input" style={{ paddingLeft: '15px', width: '400px' }}
                            onChange={(e) => setSearchState(e.target.value)} onKeyDown={(e) => handleKeyPress(e)}></input>
                        </div>
                        <div className="contract-filters-right" style={{ width: '50%' }}>
                            <div className="custom-select-status-button no-select" onClick={() => filterSelect()} style={{ display: search !== 'formulário' ? 'flex' : 'none' }}>
                                <div className="custom-select-status-button-inner">
                                    <img src="icon-filter.svg" className="svg-settings" width='25px' height='auto' alt='filter'></img>
                                </div>
                                <div style={{ width: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <p>Filtrar status</p>
                                </div> 
                            </div>
                            <div id="custom-select-status-div">
                                <div className="custom-select-status" onClick={() => setFiltro('', 'status')}>
                                    <p id="status0" style={{ marginLeft: '44px' }}>Qualquer</p>
                                </div>
                                <div className="custom-select-status" onClick={() => setFiltro('2432', 'status')}>
                                    <div className="contracts-status-color" style={{ backgroundColor: '#359be8' }}></div>
                                    <p id="status1">Rascunho</p>
                                </div>
                                <div className="custom-select-status" onClick={() => setFiltro('2433', 'status')}>
                                    <div className="contracts-status-color" style={{ backgroundColor:'#feeb3b' }}></div>
                                    <p id="status2">Ag.Revisão</p>
                                </div>
                                <div className="custom-select-status" onClick={() => setFiltro('2434', 'status')}>
                                    <div className="contracts-status-color" style={{ backgroundColor:'#fd9827' }}> </div>
                                    <p id="status3">Ag.Assinatura</p>
                                </div>
                                <div className="custom-select-status" onClick={() => setFiltro('2435', 'status')}>
                                    <div className="contracts-status-color" style={{ backgroundColor:'#2e7d32' }}></div>
                                    <p id="status4">Vigente</p>
                                </div>
                                <div className="custom-select-status" onClick={() => setFiltro('2436', 'status')}>
                                    <div className="contracts-status-color" style={{ backgroundColor:'#db2828' }}></div>
                                    <p id="status5">Encerrado</p>
                                </div>
                                <div className="custom-select-status" onClick={() => handlePendentesClick()}>
                                    <div className="contracts-status-color" style={{ backgroundColor:'#FF6347' }}></div>
                                    <p id="status6">Minhas Pendencias</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contracts-bottom">
                        <div className="contracts-bottom-title">
                            <div className="title-number contract-titles">
                                <p>Número</p>
                            </div>
                            <div className="title-title contract-titles">
                                <p>Título</p>
                            </div>
                            <div className="title-status contract-titles">
                                <p>Pessoas</p>
                            </div>
                            <div className="title-status contract-titles">
                                <p>Status</p>
                            </div>
                            <div className="contract-titles" style={{ width: '10%' }}>
                                <p>Classificação</p>
                            </div>
                            <div className="title-created contract-titles">
                                <p>Criado em</p>
                            </div>
                            <div className="title-delete contract-titles">
                                <p>Excluir</p>
                            </div>
                        </div>
                        <div className="contracts-bottom-contracts">
                        {filteredContracts.length === 0 && feedbackMessage && (
        <div className="error-feedback">
            <div className="error-message">
                <span className="error-icon">⚠️</span>
                <p>{feedbackMessage}</p>
            </div>
        </div>
    )}

    {/* Renderiza os contratos encontrados */}
    {
        filteredContracts.length > 0 && filteredContracts.map((contract, i) => (
                                    <div key={i} className="contracts-bottom-contract">
                                        
                                        <a className="contract-number contract-contents" href={`/contrato?id=${contract.id}`} >
                                            <p>{contract.number}</p>
                                        </a>
                                        <a className="contract-title contract-contents" href={`/contrato?id=${contract.id}`}>
                                            <p>{contract.title}</p>
                                        </a>
                                        {/* <a className="contract-status contract-contents" href={`/contrato?id=${contract.id}`}>
                                            
                                        </a> */}
                                        <div className="contract-people contract-contents" onClick={() => navigate(`/contrato?id=${contract.id}`)}>
                                            {getInitials(contract.shares, contract.classifier, contract.id)}
                                        </div>
                                        <a className="contract-status contract-contents" href={`/contrato?id=${contract.id}`}>
                                            <div style={{ style: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '12px', height: '12px', borderRadius: '100px', display: 'inline-block'
                                                , backgroundColor: getColor(contract.status.color) }}></div>
                                                <p style={{ display: 'inline-block', marginLeft: '10px' }}>{contract.status.name}</p>
                                            </div>        
                                        </a>
                                        <a className="contract-contents" style={{ width: '10%' }} href={`/contrato?id=${contract.id}`}>
                                            {contract.classifier !== null ? 
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#E8E8E8', borderRadius: '5px' }}>
                                                    <p style={{ padding: '5px 10px', color: '#5d5d5d' }}>{contract.classifier[0]}</p>
                                                </div> 
                                            : <p> </p>}
                                        </a>
                                        <a className="contract-created contract-contents" href={`/contrato?id=${contract.id}`}>
                                            <p>{formatDate(contract.inserted_at)}</p>
                                        </a>
                                        <div className="contract-delete contract-contents">
                                            <div className="delete-icon-container no-select" onClick={() => openDeleteModal(contract.id, contract.status.name)}>
                                                <img src="icon-delete.svg" alt="settings svg" width="25px" height="auto" className="svg-settings"></img>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="contract-page-div">
                    <div className="contract-page-div-container">
                        <div className="contract-page-flex">
                            <div className="change-page-button" id="previous-page-button" onClick={() => setFiltro(pageInfo.page - 1, 'page')}>
                                <img src="icon-arrowRight.svg" alt="arrow left" width='12px' height='auto' className="svg-settings arrow-left-icon"></img>
                            </div>
                            <p className="page-number">{pageInfo.page} de {pageInfo.pages}</p>
                            <div className="change-page-button" id="next-page-button" onClick={() => setFiltro(pageInfo.page + 1, 'page')}>
                                <img src="icon-arrowRight.svg" alt="arrow left" width='12px' height='auto' className="svg-settings"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="delete-modal">
                    <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', height: '400px', width: '360px', padding: '0px' }}>
                        <div className="delete-modal-top">
                            <p style={{ fontSize: '22px', fontWeight: 'bold' }}>Deletar contrato</p>
                        </div>
                        <div className="delete-modal-middle">
                            <img alt="lixo" src="icon-trash.svg" width='100px' height='auto' className="svg-settings"></img>
                            <p style={{ color: '#777777' }}>Você está prestes a deletar 1 documento.</p>
                        </div>
                        <div className='delete-modal-bottom'>
                            <button className="modal-button" onClick={() => deleteContract()}>Ok, deletar</button>
                            <button className="modal-button-return" onClick={() => cancelDeleteContract('delete-modal')}>Voltar</button>
                        </div>
                        
                        
                    </div>
                </div>

                <div className="modal" id="non-delete-modal">
                    <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', height: '350px', width: '360px', padding: '0px' }}>
                        <div className="delete-modal-top">
                            <p style={{ fontSize: '22px', fontWeight: 'bold' }}>Deletar contrato</p>
                        </div>
                        <div className="delete-modal-middle" style={{ height: '65%' }}>
                            <img alt="lixo" src="icon-trash.svg" width='100px' height='auto' className="svg-settings"></img>
                            <p style={{ color: '#777777', fontSize: '18px', marginTop: '15px' }}>Este contrato não pode ser deletado pois está Ag.Assinatura ou Vigente.</p>
                        </div>
                        <div className='delete-modal-bottom' style={{ height: '20%' }}>
                            <button className="modal-button-return" onClick={() => cancelDeleteContract('non-delete-modal')}>Voltar</button>
                        </div>
                    </div>
                </div>

                <div className="modal" id="form-modal">
                    <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', height: '200px', width: '360px', padding: '10px 0px' }}>
                        <div style={{ height: '33%', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p>Link de formulário do cliente</p>
                        </div>
                        <div style={{ height: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ color: 'white', display: 'flex', width: '150px', height: '30px', flexDirection: 'row', cursor: 'pointer', backgroundColor: '#03a9f4', padding: '5px', 
                            borderRadius: '5px', alignItems: 'center', justifyContent: 'space-evenly' }} onClick={() => copyFormLink()}>
                                <img src="icon-copy.svg" width='20px' className="svg-settings-white" height='auto' alt="copy icon"/>
                                <p id="copy-link" style={{ fontSize: '20px' }}>Copiar Link</p>
                            </div>
                        </div>
                        <div style={{ height: '33%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button className="modal-button-return" onClick={() => document.getElementById('form-modal').style.display = 'none'}>Fechar</button>
                        </div>
                    </div>
                </div>

                {/* <h1>Contratos</h1>
                <button >Novo Contrato</button> */}
            </div>

        </div>
    )
}

export default Contratos